.ArticlesPage {

    &__in {
        padding: 40px 0;
    }

    &__body {

        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;

        &_item {
            width: calc((100% / 2) - 30px);
            margin: 0 15px 30px 15px;
        }
    }
}

@media (max-width: 768px) {
    .ArticlesPage {

        &__body {

            &_item {
                width: calc(100% - 30px);
            }
        }
    }
}