.Button.load {
    pointer-events: none;
    touch-action: none;

}

.Button {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 11px;
    transition: all .3s ease;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    

    &:hover {
        opacity: .7;
    }

    &:active {
        transform: scale(0.95);
    }

    &:disabled {
        pointer-events: none;
        touch-action: none;
        background-color: #eaeaea !important;
        border-color: #eaeaea !important;
    }

    &__load {
        font-size: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text {
        transition: all .3s ease;
        text-align: center;
        font-weight: 500;
        color: #fff;
    }
}

.Button.warning {
    background-color: var(--orange);
    border-color: var(--orange);
}

.Button.warning-tr {
    border-color: var(--orange);
    background-color: transparent;

    .Button__text{
        color: var(--orange);
    }

    &:hover {
        background-color: var(--orange);

        .Button__text {
            color: #fff;
        }
    }
}

.Button.success {
    background-color: var(--green);
    border-color: var(--green);
}

@media (max-width: 768px) {
    .Button {
        padding: 10px 10px;
        
        &__text {
            font-size: 14px;
            line-height: 26px;
            
        }

    }
}