.Adv {

    &__in {
        padding: 40px 0;
    }

    &__title {
        margin-bottom: 30px;
    }

    &__list {
        display: flex;
        justify-content: space-between;

        &_item {
            width: calc(100% / 3.3);
            padding: 25px 28px;
            box-shadow: inset 0px 1px 0px #C4CDD5, inset 0px -1px 0px #C4CDD5;
            display: flex;
            flex-direction: column;
            align-items: center;

            &_img {
                margin-bottom: 20px;
                width: 53px;
                height: 53px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    display: flex;
                }
            }

            &_name {
                text-align: center;
                line-height: 26px;
                font-weight: 500;
            }
        }
    }

    &__body {
        margin-top: 60px;
        display: flex;
        align-items: flex-start;

        justify-content: space-between;

        &_descr {
            width: 54%;

            &_head {
                margin-bottom: 30px;
                font-weight: 500;
                font-size: 25px;
                line-height: 42px;
            }

            &_text {
                line-height: 32px;
                font-weight: 500;
                margin-bottom: 30px;
            }

            &_list {
                
                &_item {
                    padding-left: 30px;
                    position: relative;
                    font-weight: 500;
                    margin-bottom: 15px;

                    &:after {
                        position: absolute;
                        width: 18px;
                        height: 18px;
                        background-color: var(--orange);
                        content: url('../../assets/svg/check.svg');
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }

        &_img {
            width: 44%;
            position: relative;
            padding: 0 30px;
            // height: 520;

            img {
                position: relative;
                z-index: 3;
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: flex;

            }

        }
    }
}

@media (max-width: 768px) {
    .Adv {

        &__in {
            padding: 25px 0;
        }

        &__title {
            margin-bottom: 25px;
        }

        &__list {
            flex-wrap: wrap;

            &_item {
                width: 100%;
                margin-bottom: 20px;
                border-left: none;
                border-right: none;
            }
        }

        &__body {
            flex-wrap: wrap;
            margin-top: 20px;

            &_img {
                order: 1;
                width: 100%;
            }

            &_descr {
                width: 100%;
                order: 2;

                &_text {
                    font-weight: 400;
                    line-height: 120%;
                }
            }
        }
    }
}