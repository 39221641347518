.OrderItem {
    display: flex;
    position: relative;
    &__remove {
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 20px;
        transition: all .2s ease;
        cursor: pointer;

        &:hover {
            color: red;
        }
    }
    &__img {
        width: 136px;
        height: 120px;
        overflow: hidden;
        flex: 0 0 auto;
        

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: flex;
        }
    }

    &__body {
        padding: 12px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        &_name {
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
        }

        &_action {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            flex-wrap: wrap;

            &_price {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}

@media (max-width: 620px) {
    .OrderItem {
        padding-right: 20px;

        &__img {
            width: 100px;
            height: 100px;

        }

        &__body {

            &_name {
                margin-bottom: 10px;
            }

            &_action {
                flex-wrap: wrap;


                &_price {
                    margin-top: 10px;
                    width: 100%;
                }
            }
        }
    }
}