.Ribbon {

    &__in {
        padding: 40px 0;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        &_title {

        }
    }

    &__body {

        &_slider {
            padding-bottom: 40px !important;

            .swiper-wrapper {
                align-items: unset !important;
            }
            
            &_sl {
                
                height: unset !important;

                .Product {
                    height: 100%;
                }
            }

            &_scrollbar {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 20px;

                .swiper-scrollbar-drag {
                    height: 100%;
                    background-color: #D9D9D9;
                    cursor: pointer;
                    

                }
            }
        }
    }
}