.EmptyList {
    font-size: 20px;
    opacity: .5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    padding: 20px 0;
    &__icon {
        margin-bottom: 20px;
        font-size: 40px;
    }

    &__text {
        text-align: center;
    }
}