.OrderForm {

    padding: 24px 30px;

    &__part {


        &_head {
            margin-bottom: 15px;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
        }

        &_body {

            &_row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                flex-wrap: wrap;
            }
            &_item.fill {
                width: 100%;
            }

            &_item {
                width: 48%;

                &_input {

                    &_label {
                        margin-bottom: 5px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                    }

                    input {
                        padding: 12px 20px;
                        background: rgba(247, 171, 58, 0.04);
                        border: 1px solid rgba(247, 171, 58, 0.2);
                        display: block;
                        width: 100%;
                        font-size: 12px;
                        line-height: 20px;
                    }

                    
                }
                &_select {

                    &_label {
                        margin-bottom: 5px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                    }
                }

                &_text {

                    &_label {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: #454F5B;
                        margin-bottom: 4px;
                    }

                    textarea {
                        display: block;
                        width: 100%;
                        background: rgba(247, 171, 58, 0.04);
                        border: 1px solid rgba(247, 171, 58, 0.2);
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        padding:12px;
                        resize: none;
                        width: 100%;
                        height: 76px;
                    }
                }
            }
        }
    }
}

@media (max-width: 620px) {
    .OrderForm {

        padding: 15px;

        &__part {

            &_head {

            }

            &_body {

                &_row {
                    flex-wrap: wrap;
                    margin-bottom: 0;
                }

                &_item {
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
        }
    }
}