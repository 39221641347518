.Fb {
    background: #F5F7F9;
    &__in {
        padding: 20px 0;
        padding-bottom: 55px;
        position: relative;
    }

    &__cb {
        position: absolute;
        top: 45px;
        left: 0;
        width: 70px;
        height: 70px;
        border-radius: 100%;
        background-color: var(--orange);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #fff;
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
        transition: all .3s ease;

        &:hover {
            opacity: .6;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 10px;
    }

    &__text {
        text-align: center;
        
        max-width: 518px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    &__rw {
        max-width: 750px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        &_item {
            width: calc(100% / 3.1);
            height: 100%;
        }

        &_item-inp {
            input {
                height: 100%;
                display: flex;
                align-items: center;
                padding: 12px 15px;
                border-radius: 11px;
                background-color: #fff;
                width: 100%;

                &:focus {
                    outline: 1px solid var(--orange);
                }
            }
        }

        &_item-btn {
            button {
                width: 100%;
                
            }
        }
    }
}

@media (max-width: 768px) {
    .Fb {

        &__cb {
            display: none;
        }

        &__text {
            margin-bottom: 20px;
            font-size: 14px;
        }
        &__rw {
            flex-wrap: wrap;

            &_item {
                width: 100%;
                margin-bottom: 10px;

                input {
                    padding: 13px 16px;
                    font-size: 14px;
                }
            }
        }
    }
}