.FilterList {
    width: 100%;
    margin-left: 15px;

    &__top {
        display: flex;
        align-self: flex-start;
        justify-content: space-between;
        margin-bottom: 15px;
        

        &_list {
            display: flex;
            flex-wrap: wrap;
            // padding-top: 10px;

            &_item {
                display: flex;
                align-items: center;
                margin: 5px 10px 5px 0 !important;
                border-radius: 15px !important;
                font-size: 14px !important;
                display: flex !important;
                align-items: center;
                padding: 5px 10px !important; 
                

                span {
                    font-size: 15px;
                    &:hover {
                        color:red;
                    }
                }

                
            }
        }

        &_sort {
            .ant-select {
                .ant-select-selector {
                    border-color: var(--orange) !important;
                    border-radius: 10px !important;

                }
            }
        }
    }

    &__body {
        display: flex;
        flex-wrap: wrap;

        &_item {
            width: calc(100% / 3);

            .Product {
                height: 100%;
            }
        }
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }
}

@media (max-width: 1000px) {
    .FilterList {

        &__body {

            &_item {
                width: calc(100% / 2);
            }
        }
    }
}

@media (max-width: 768px) {
    .FilterList {
        width: 100%;
        margin-left: 0;
        &__top {
            flex-wrap: wrap;

            &_list {
                order: 2;
                width: 100%;
            }

            &_sort {
                margin-bottom: 20px;
                order: 1;
                width: 100%;

                .FilterList__top_sort_select {
                    width: 100%;
                    font-size: 11px;
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .FilterList {

        &__body {

            &_item {
                width: 100%;
            }
        }
    }
}