.FilterPage {


    &__in {
        padding: 40px 0;
    }

    &__head {
        margin-bottom: 15px;

        span {
            font-size: 20px;
            line-height: 24px;
            color: #CDCDCD;
        }
    }

    &__body {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}

@media (max-width: 768px) {
    .FilterPage {

        &__head {
            font-weight: 400 !important;
            font-size: 25px !important;
            line-height: 35px !important;

            span {
                font-weight: 400 !important;
            font-size: 16px !important;
            line-height: 26px !important;
            }
        }
        &__body {
            flex-wrap: wrap;
        }
    }
}