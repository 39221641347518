.HomeCat {

    &__in {
        padding: 40px 0;
    }

    &__title {
        text-align: center;
        margin-bottom: 30px;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        margin-left: -8px;
        margin-right: -8px;

        &_item {
            width: calc((100% / 2) - 16px);
            margin: 0 8px;
            margin-bottom: 16px;
        }
    }

    &__action {
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 768px) {

    .HomeCat {

        &__in {
            padding: 25px 0;
        }

        &__title {
            margin-bottom: 20px;
        }

        &__body {
            
            &_item {
                width: 100%;

                
            }
        }
    }
}