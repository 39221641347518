.Hero {

    &__in {
        padding-bottom: 25px;
    }

    &__slider {
        padding-bottom: 40px !important;

        &_sl {
            height: 472px !important;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 30px 50px;

            &_img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: flex;
                }
            }

            &_content {
                position: relative;
                color: #fff;
                max-width: 450px;

                &_title {
                    font-weight: 500;
                    font-size: 31px;
                    line-height: 36px;
                    letter-spacing: 0.05em;
                    margin-bottom: 10px;
                }

                &_text {
                    margin-bottom: 10px;
                    font-weight: 500;
                }

                &_action {
                    display: flex;
                    align-items: center;
                }

            }
        }
        &_action {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &_pag {
            // width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
            &_item {
                width: 24px;
                height: 24px;
                border-radius: 100%;
                position: relative;
                transition: all .3s ease;
                border: 1px solid transparent;
                margin: 0 5px;
                cursor: pointer;
                flex: 0 0 auto;

                &:after {
                    position: absolute;
                    width:  8px;
                    height: 8px;
                    border-radius: 100%;
                    content: '';
                    background-color: var(--orange);
                    transition: all .3s ease;
                    top: calc(50% - 4px);
                    left: calc(50% - 4px);
                }
            }

            &_item.active {
                border-color: var(--orange);
            }
        }

        &_nav {
            font-size: 24px;
            color: var(--text);
            transition: all .3s ease;
            cursor: pointer;
            margin: 0 12px;
            
            &:hover {
                color: var(--orange);
            }
        }
    }
}

@media (max-width: 768px) {
    .Hero {

        &__slider {

            &_sl {
                height: 400px !important;
                padding: 20px 30px;
            }
        }
    }
}

@media (max-width: 550px) {
    .Hero {

        &__slider {

            &_sl {
                height: 250px !important;

                &_content {

                    &_title {
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 18px;
                        margin-bottom: 0;
                    }

                    &_text {
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 26px;
                    }

                    &_action {

                    }
                }
            }

            &_action {
                justify-content: space-between;
            }
        }
    }
}