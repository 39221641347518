.CatalogPage {

    &__in {
        padding-bottom: 40px;
    }

    &__head {
        margin-bottom: 15px;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        margin-left: -8px;
        margin-right: -8px;

        &_item {
            width: calc((100% / 2) - 16px);
            margin: 0 8px 16px 8px;
        }
    }
}

@media (max-width: 620px) {

    .CatalogPage {

        &__body {

            &_item {
                width: calc(100% - 16px);
            }
        }
    }
}