.AboutBrands {

    &__in {
        padding: 40px 0;
    }

    &__head {
        text-align: center;
        text-decoration: underline;
        color: var(--orange);
        text-transform: uppercase;
    }

    
    &__body {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: -15px;
        margin-right: -15px;

        &_item {
            width: calc((100% / 6) - 30px);
            margin: 0 15px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: flex;
            }
            
        }
    }
    
}

@media (max-width: 768px) {
    .AboutBrands {
        
        &__body {
            justify-content: space-between;
            margin-left: 0;
            margin-right: 0;

            &_item {
                width: 48%;
                margin: 0;
                
            }
        }
    }
}