.Checkbox {

    input {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        pointer-events: none;
        touch-action: none;
        z-index: -1;
    }

    input:checked ~ .Checkbox__body {

        .Checkbox__body_icon {
            background-color: var(--orange);
            border-color: var(--orange);

            &:after {
                transform: scale(1);
            }
        }
    }

    &__body {
        display: flex;
        align-items: flex-start;
        

        &_icon {
            flex: 0 0 auto;
            margin-right: 8px;
            width: 16px;
            height: 16px;
            border: 1px solid #9E9AA4;
            background-color: #fff;
            position: relative;
            transition: all .2s ease;

            &:after {
                position: absolute;
                content: '';
                width: 10px;
                height: 10px;
                top: calc(50% - 5px);
                left: calc(50% - 5px);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-image: url('../../assets/checked.svg');
                transform: scale(0);
                transition: all .2s ease;
            }
        }

        &_text {
            font-size: 14px;
            line-height: 19px;

        }
    }
}