.Basket {
    .ant-modal-content {
        background-color: unset;
        box-shadow: none;
        padding: 0;
        .ant-modal-close {
            display: none;
        }

        .ant-modal-footer {
            display: none;
        }

    }

    &__body {
        background-color: #fff;
        border-radius: 21px;
        overflow: hidden;

        &_top {
            padding: 15px 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #eaeaea;

            &_name {
                font-weight: 500;
                font-size: 20px;
                line-height: 34px;
            }

            &_close {
                font-size: 20px;
                transition: all .2s ease;
                cursor: pointer;
                &:hover {
                    color: red;
                }
            }
        }

        &_main {
            padding: 25px 40px;

            &_list {


            }

            &_item {
                display: flex;

                &_img {
                    flex: 0 0 auto;
                    width: 120px;
                    height: 120px;
                    background-color: #cdcdcd;
                    border-radius: 11px;
                    overflow: hidden;
                    margin-right: 24px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        display: flex;
                    }
                }

                &_body {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }

            &_action {

                &_value {
                    line-height: 26px;
                    margin-bottom: 10px;
                    span {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                &_btns {
                    display: flex;
                    
                    justify-content: space-between;

                    &_item {
                        max-width: 250px;
                        width: 100%;

                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 650px) {
    .Basket {

        &__body {

            &_top {
                padding: 15px;

                &_name {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            
            &_main {
                padding: 15px;
                &_action {
                    &_btns {
                        flex-wrap: wrap;

                        &_item {
                            width: 100%;
                            max-width: unset;
                            margin-bottom: 10px;
                        }
                    }
                }
            }


        }
    }
}