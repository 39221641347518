.AboutTeam {

    &__in {
        padding: 40px 0;
    }

    &__head {
        margin-bottom: 25px;
        text-transform: uppercase;
        color: var(--orange);
        text-decoration: underline;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        margin-left: -7.5px;
        margin-right: -7.5px;


        &_item {
            width: calc((100% / 3) - 15px);
            margin: 0 7.5px;
            margin-bottom: 10px;

            &_img {
                height: 395px;
                overflow: hidden;
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: flex;
                }
            }

            &_descr {
                background-color: #FAFAFA;

                padding: 20px;

                &_pr {
                    text-align: center;
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 130%;
                    margin-bottom: 15px;
                }

                &_name {
                    text-align: center;
                    font-weight: 300;
                    font-size: 24px;
                    line-height: 130%;
                }

            }
        }
    }
}

@media (max-width: 768px) {
    .AboutTeam {

        &__in {

        }


        &__head {

        }
        
        &__body {
            flex-wrap: wrap;

            &_item {
                width: calc((100% / 2) - 15px);
            }
        }
    }
}

@media (max-width: 620px) {
    .AboutTeam {

        &__body {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            &_item {
                width: 100%;
            }
        }
    }
}