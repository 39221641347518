* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;

}

button,input,textarea,select {
    font: inherit;
    border: none;
    background-color: unset;
}

li {
    list-style: none;
}

#root {
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    font-family: 'SF Pro Display';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--text);
    letter-spacing: 0.05em;
    overflow-x: hidden !important;
    transition: opacity .2s ease;
}

body.touch-disabled {
    pointer-events: none;
    touch-action: none;
    opacity: .5;
}

h1,h2,h3,h4,h5,h6 {
    color: inherit;
}

.container {
    max-width: 1200px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
}

.section-title {
    font-size: 31px;
    line-height: 62px;
    font-weight: 400;
    color: var(--text);
}

@media (max-width: 768px) {
    .section-title {
        font-weight: 500;
        font-size: 21px;
        line-height: 31px;
    }
}