.ArticleCard {
    border-radius: 11px;
    overflow: hidden;

    &__img {
        background-color: #cdcdcd;
        height: 330px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }

    &__body {
        padding: 15px;

        &_name {
            font-weight: 600;
            font-size: 21px;
            line-height: 42px;
            text-transform: uppercase;
            margin-bottom: 15px;
            color: var(--text);
            
            &:hover {
                color: var(--orange);
            }
        }

        &_descr {
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 30px;
        }

        &_action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            &_info {
                display: flex;
                flex-direction: column;
                font-weight: 500;
                opacity: .7;
                margin: 4px 0;

                &_part {
                   
                    margin-bottom: 4px;
                }

                &_date {

                }
            }

            &_link {
                display: block;
                width: 220px;
                flex: 0 0 auto;
                

                button {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .ArticleCard {

        &__body {

            &_descr {
                font-size: 16px;
            }

            &_action {

                &_link {
                    width: 100%;
                    margin-top: 10px;
                }
            }
        }
    }
}

@media (max-width: 620px) {

    .ArticleCard {

        &__img {
            height: 220px;
        }
    }
}