.Header {
    box-shadow: 0px 3px 44px rgba(0, 0, 0, 0.07);
    position: relative;
    z-index: 1000;

    &__in {
        
    }

    &__top {
        padding: 15px 0;
        background-color: var(--text);
        &_in {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__info {
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: flex-end;
        padding-right: 50px;

        &_item {
            color: #fff;
            display: flex;
            margin: 5px 0;

            &_name {
                opacity: .6;
                margin-right: 5px;
            }
        }
    }

    &__main {
        background-color: #fff;
        padding: 15px 0;
        &_in {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
    }

    &__burger {
        display: none;
    }

    &__logo {
        width: 120px;
        height: 48px;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: flex;
        }
    }

    &__tel {
        display: flex;
        align-items: center;
        color: #fff;
        transition: all .3s ease;
        white-space: nowrap;

        &_icon {
            margin-right: 4px;
            transform: scale(-1, 1);
        }

        &:hover {
            color: var(--orange);
        }
    }

    &__search {
        width: 100%;
        margin: 0 50px;
        max-width: 588px;
        
    }

    &__action {

        &_item {
            position: relative;

            &_not {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 10px;
                line-height: 10px;
                padding: 4px;
                background-color: var(--orange);
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .Header {

        &__top {
            
            &_in {
                justify-content: flex-end;
            }
        }

        &__burger.active {

            span {
                &:nth-child(1) {
                    top: calc(50% - 1px);
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    bottom: calc(50% - 1px);
                    transform: rotate(-45deg);
                }
            }
        }

        &__burger {
            width: 30px;
            height: 20px;
            position: relative;
            display: block;

            span {
                height: 2px;
                width: 100%;
                background-color: #000;
                position: absolute;
                transition: all .3s ease;
                
                &:nth-child(1) {
                    top: 0;
                    left: 0;
                }

                &:nth-child(2) {
                    top: calc(50% - 1px);
                    left: 0;
                }

                &:nth-child(3) {
                    bottom: 0;
                    left: 0;
                }
                
            }
        }

        &__nav {
            display: none;
        }

        &__logo {
            display: none;
        }

        &__search {
            display: none;
        }

        &__info {
            display: none;
        }

        &__action {

            &_item {
                
                .IconButton {
                    color: var(--orange) !important;
                    &__text {
                        display: none;

                    }
                }
            }
        }
    }
}
