.AboutInd {
    background-color: 
    #24272E;
    &__in {
        padding: 40px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &_val {
            font-weight: 500;
            font-size: 55px;
            line-height: 72px;
            color: #fff;
            text-align: center;
            margin-bottom: 12px;
        }

        &_text {
            text-align: center;
            font-weight: 300;
            font-size: 18px;
            line-height: 32px;
            color: var(--orange);
        }
    }
}

@media (max-width: 768px) {
    .AboutInd {

        &__in {
            flex-wrap: wrap;
        }

        &__item {
            width: 48%;

            &_val {
                font-size: 38px;
            }
        }
    }
}